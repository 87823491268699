import { Injectable } from "@angular/core";
import {
  APIService, ModelSurveyByParticipantTypeDateCompositeKeyConditionInput,
  ModelSurveyByRespondentTypeDateCompositeKeyConditionInput,
  ModelSurveyFilterInput,
  SurveyType,
  UpdateSurveyInput
} from "./API.service";
import { Memoize, MEMOIZE_FN_MAP } from "@inthraction/utils";
import { StandupSurvey, Survey } from "@inthraction/data-models";
import {
  EventSurveyResponseOptions,
  ObjectiveSurveyResponseScores,
  round,
  ShoutOutTypeStatus,
  SurveyResponseFilterDirection,
  SurveyStatus
} from "@inthraction/codes";
import { BaseService, GetAllOptions } from "./base.service";
import * as moment from "moment";
import { ShoutOutService } from "./shout-out.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class SurveyService extends BaseService {

  constructor(
    protected api: APIService,
    protected shoutOutService: ShoutOutService,
    protected authService: AuthService
  ) {
    super(api, authService);
  }

  public static getFivePointResponseScore(response: number): number {
    let score: number;
    switch (response) {
      case 1:
        score = ObjectiveSurveyResponseScores.NEEDS_IMPROVEMENT;
        break;
      case 2:
        score = ObjectiveSurveyResponseScores.LOW_MEETS;
        break;
      case 3:
        score = ObjectiveSurveyResponseScores.MEETS;
        break;
      case 4:
        score = ObjectiveSurveyResponseScores.HIGH_MEETS;
        break;
      case 5:
        score = ObjectiveSurveyResponseScores.EXCEEDS;
        break;
    }
    return score;
  }

  static calculateNextSurveyDate(startDate: string, cadence: string, endDate: string): string {
    let nextSurveyDate = moment(startDate, "YYYY-MM-DD");
    switch (cadence) {
      case "WEEKLY": {
        nextSurveyDate = nextSurveyDate.add(7, "days");
        break;
      }
      case "BI_WEEKLY": {
        nextSurveyDate = nextSurveyDate.add(14, "days");
        break;
      }
      case "MONTHLY": {
        nextSurveyDate = nextSurveyDate.add(28, "days");
        break;
      }
      case "QUARTERLY": {
        nextSurveyDate = nextSurveyDate.add(84, "days");
        break;
      }
      case "ANNUALLY": {
        nextSurveyDate = nextSurveyDate.add(364, "days");
        break;
      }
    }
    if (endDate && endDate < nextSurveyDate.format("YYYY-MM-DD")) {
      return endDate;
    }
    if (moment().format("YYYY-MM-DD") > nextSurveyDate.format("YYYY-MM-DD")) {
      return SurveyService.calculateNextSurveyDate(nextSurveyDate.format("YYYY-MM-DD"), cadence, endDate);
    }
    return nextSurveyDate.format("YYYY-MM-DD");
  }

  public static round(value: number, precision: number): number {
    return round(value, precision);
  }

  clearMemoizedOpenSurveys() {
    if (MEMOIZE_FN_MAP.has("getPendingResponseSurveysByRespondentEmailMemoize")) {
      MEMOIZE_FN_MAP.get("getPendingResponseSurveysByRespondentEmailMemoize").clear();
    }
  }

  clearMemoizedInspHRationSurveys(email: string) {
    if (MEMOIZE_FN_MAP.has("getReceivedInspHRations")) {
      MEMOIZE_FN_MAP.get("getReceivedInspHRations").clear();
    }
    if (MEMOIZE_FN_MAP.has("getGivenOrReceivedInspHRactions")) {
      MEMOIZE_FN_MAP.get("getGivenOrReceivedInspHRactions").clear();
    }
    if (MEMOIZE_FN_MAP.has("getOpenInspHRationSurveyForEmployee")) {
        MEMOIZE_FN_MAP.get("getOpenInspHRationSurveyForEmployee").clear();
    }
  }

  clearMemoizedSurvey(surveyID: string) {
    if (MEMOIZE_FN_MAP.has("getSurveyByIDMemoize")) {
      MEMOIZE_FN_MAP.get("getSurveyByIDMemoize").delete(surveyID);
    }
  }

  async updateInspHRationSurvey(survey: UpdateSurveyInput): Promise<Survey> {
    const result = await this.updateSurvey(survey);
    this.clearMemoizedInspHRationSurveys(result.participantEmail);
    this.clearMemoizedInspHRationSurveys(result.respondentEmail);
    this.clearMemoizedSurvey(result.id);
    return result;
  }

  async updateSurvey(survey: UpdateSurveyInput): Promise<Survey> {
    const surveyResult = await this.api.UpdateSurvey(survey);
    this.clearMemoizedOpenSurveys();
    this.clearMemoizedSurvey(surveyResult.id);
    return surveyResult;
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getPendingResponseSurveysByRespondentEmailMemoize(organizationID:string, email: string, objectID?: string): Promise<Survey[]> {
    return this.getPendingResponseSurveysByRespondentEmail(organizationID, email, objectID);
  }

  async getPendingResponseSurveysByRespondentEmail(organizationID:string, email: string, objectID?: string): Promise<Survey[]> {
    const surveyDate = moment().subtract(1, 'year').subtract(1, 'day').toISOString();
    const filter: ModelSurveyFilterInput = {
      and: [
        { responseReceived: { eq: false } },
        { status: { ne: SurveyStatus.DELETED } },
        { status: { ne: SurveyStatus.MISSED } },
        { surveyDate: {gt: surveyDate } },
      ]
    };

    if (objectID) {
      filter.and.push({ objectID: { eq: objectID } });
    }

    const range: ModelSurveyByParticipantTypeDateCompositeKeyConditionInput = { beginsWith: {surveyType:SurveyType.INSPHRATION} };
    return this.getAllWithHashKeyAndRangeKeyAndSortDirection(this.api.SurveysByRespondentTypeDate, email, range, null, filter,  {limit: 100000});
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getSurveyByIDMemoize(surveyId: string): Promise<Survey> {
    return this.getSurveyByID(surveyId);
  }

  async getSurveyByID(surveyId: string): Promise<Survey> {
    if (surveyId) {
      const standupSurvey = await this.api.GetStandupSurvey(surveyId);
      if (standupSurvey) {
        return new StandupSurvey(standupSurvey);
      } else {
        return await this.api.GetSurvey(surveyId);
      }
    } else {
      return null;
    }


    return surveyId ? await this.api.GetSurvey(surveyId) : null;
  }

  async getSurveysByObjectID(organizationID:string, objectID: string): Promise<Survey[]> {
    const filter = { objectID: { eq: objectID } };
    return this._getSurveys(organizationID, filter);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  getSurveysByObjectIDMemoize(organizationID: string, objectID: string): Promise<Survey[]> {
    return this.getSurveysByObjectID(organizationID, objectID);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getSurveyMemoize(surveyId: string): Promise<Survey> {
    return this.getSurveyByID(surveyId);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getRecentSurveysByParticipantMemoize(organizationID: string, participantEmail: string, oldestSurveyResponseDate?: string): Promise<Survey[]> {
    const surveyDate = moment().subtract(1, 'year').subtract(1, 'day').toISOString();
    const filter = {
      and: [
        { responseReceivedDate: { gt: oldestSurveyResponseDate } },
        { status: { ne: SurveyStatus.DELETED } },
        { status: { ne: SurveyStatus.MISSED } },
        { surveyDate: {gt: surveyDate } },
      ]
    };
    const range: ModelSurveyByParticipantTypeDateCompositeKeyConditionInput = { beginsWith:{surveyType: SurveyType.OBJECTIVE} };
    const results = []
    results.push(...await this.getAllWithHashKeyAndRangeKeyAndSortDirection(this.api.SurveysByParticipantTypeDate, participantEmail, range, null, filter,  {limit: 100000}));
    range.beginsWith = {surveyType: SurveyType.EVENT};
    const eventResponses = await this.getAllWithHashKeyAndRangeKeyAndSortDirection<Survey>(this.api.SurveysByParticipantTypeDate, participantEmail, range, null, filter,  {limit: 100000});
    results.push(...eventResponses.filter(r => r.surveyResponse > EventSurveyResponseOptions.DID_NOT_ATTEND));
    return results;
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getEventSurveysByRespondentMemoize(organizationID: string, respondentEmail: string, surveyResponseFilterDirection?: SurveyResponseFilterDirection, surveyResponseOption?: EventSurveyResponseOptions, oldestSurveyResponseDate?: string): Promise<Survey[]> {
    return this.getEventSurveyByEmail(organizationID, RetrievalType.RESPONDENT, respondentEmail, surveyResponseFilterDirection, surveyResponseOption, oldestSurveyResponseDate);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getEventSurveysByParticipantMemoize(organizationID: string, participantEmail: string, surveyResponseFilterDirection?: SurveyResponseFilterDirection, surveyResponseOption?: EventSurveyResponseOptions, oldestSurveyResponseDate?: string): Promise<Survey[]> {
    return this.getEventSurveyByEmail(organizationID, RetrievalType.PARTICIPANT, participantEmail, surveyResponseFilterDirection, surveyResponseOption, oldestSurveyResponseDate);
  }

  async getSurveysByObjectIDAndEmail(organizationID: string, objectID: string, email: string): Promise<Survey[]> {
    const surveyDate = moment().subtract(1, 'year').subtract(1, 'day').toISOString()
    const filter = {
      and: [
        { objectID: { eq: objectID } },
        { surveyDate: {gt: surveyDate } },
      ]
    };

    const results = []
    results.push(...await this.getAllWithHashKeyAndRangeKeyAndSortDirection(this.api.SurveysByParticipantTypeDate, email, null, null, filter,  {limit: 100000}));
    results.push(...await this.getAllWithHashKeyAndRangeKeyAndSortDirection(this.api.SurveysByRespondentTypeDate, email, null, null, filter,  {limit: 100000}));

    return results;
  }

  async getEventSurveysWithFeedback(organizationID: string, participantEmail: string, startDate?: string, endDate?: string): Promise<Survey[]> {
    if (!startDate) {
      startDate = moment().subtract(1, 'year').format('YYYY-MM')
    }
    const filter: ModelSurveyFilterInput = {
      and: [
        { feedback: { attributeExists: true } },
        { surveyDate: {ge: startDate } },
      ]
    };
    if (endDate) {
      filter.and.push({ surveyDate: {le: endDate }})
    }

    const range: ModelSurveyByParticipantTypeDateCompositeKeyConditionInput = { beginsWith: {surveyType:SurveyType.EVENT}};
    return this.getAllWithHashKeyAndRangeKeyAndSortDirection(this.api.SurveysByParticipantTypeDate, participantEmail, range, null, filter, {limit: 100000});
  }

  /**
   * Super Admin Function
   */
  async getAllEventSurveysForRange(organizationID: string, startDate?: string, endDate?: string): Promise<Survey[]> {
    if (!startDate) {
      startDate = moment().subtract(30, "days").toISOString();
    }
    if (!endDate) {
      endDate = moment().toISOString();
    }
    const filter: ModelSurveyFilterInput = {
      and: [
        { surveyDate: {ge: startDate } },
        { surveyDate: {le: endDate } }
      ]
    };
    const range: ModelSurveyByParticipantTypeDateCompositeKeyConditionInput = { beginsWith: {surveyType:SurveyType.EVENT} };
    return this.getAllWithHashKeyAndRangeKeyAndSortDirection(this.api.SurveysByOrgTypeDate, organizationID, range, null, filter, {limit: 100000});
  }

  /**
   * Super Admin Function
   */
  async getAllObjectiveSurveysForRange(organizationID: string, startDate?: string, endDate?: string): Promise<Survey[]> {
    if (!startDate) {
      startDate = moment().subtract(30, "days").toISOString();
    }
    if (!endDate) {
      endDate = moment().toISOString();
    }
    const filter: ModelSurveyFilterInput = {
      and: [
        { surveyDate: {ge: startDate } },
        { surveyDate: {le: endDate } }
      ]
    };

    const range: ModelSurveyByParticipantTypeDateCompositeKeyConditionInput = { beginsWith: {surveyType:SurveyType.OBJECTIVE} };
    return this.getAllWithHashKeyAndRangeKeyAndSortDirection(this.api.SurveysByOrgTypeDate, organizationID, range, null, filter, {limit: 100000});
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getOpenInspHRationSurveyForEmployee(organizationID: string, respondentEmail: string): Promise<Survey[]> {
    const surveyDate =  moment().subtract(1, 'year').subtract(1, 'day').toISOString();
    const filter: ModelSurveyFilterInput = {
      and: [
        { responseReceived: { eq: false } },
        { status: { ne: SurveyStatus.MISSED } },
        { surveyDate: {gt: surveyDate } }
      ]
    };

    const range: ModelSurveyByParticipantTypeDateCompositeKeyConditionInput = { beginsWith: {surveyType:SurveyType.INSPHRATION} };

    return this.filterForDeletedShoutOutTypes(organizationID, await this.getAllWithHashKeyAndRangeKeyAndSortDirection(this.api.SurveysByRespondentTypeDate, respondentEmail, range, null, filter,  {limit: 100000}));

  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getGivenOrReceivedInspHRactions(organizationID: string, email: string): Promise<Survey[]> {
    const surveyDate =  moment().subtract(1, 'year').subtract(1, 'day').toISOString();
    const filter: ModelSurveyFilterInput = {
      and: [
        { responseReceived: { eq: true } },
        { status: { ne: SurveyStatus.MISSED } },
        { surveyDate: {gt: surveyDate } }
      ]
    };

    const range: ModelSurveyByParticipantTypeDateCompositeKeyConditionInput = { beginsWith: {surveyType:SurveyType.INSPHRATION} };

    const results = []
    results.push(...await this.getAllWithHashKeyAndRangeKeyAndSortDirection(this.api.SurveysByParticipantTypeDate, email, range, null, filter,  {limit: 100000}));
    results.push(...await this.getAllWithHashKeyAndRangeKeyAndSortDirection(this.api.SurveysByRespondentTypeDate, email, range, null, filter,  {limit: 100000}));

    return this.filterForDeletedShoutOutTypes(organizationID, results);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getReceivedInspHRations(organizationID: string, participantEmail: string, objectId?: string): Promise<Survey[]> {
    const surveyDate =  moment().subtract(1, 'year').subtract(1, 'day').toISOString();
    const filter: ModelSurveyFilterInput = {
      and: [
        { responseReceived: { eq: true } },
        { status: { ne: SurveyStatus.MISSED } },
        { surveyDate: {gt: surveyDate } }
      ]
    };
    if (objectId) {
      filter.and.push({ objectID: { eq: objectId } });
    }

    const range: ModelSurveyByParticipantTypeDateCompositeKeyConditionInput = { beginsWith: {surveyType:SurveyType.INSPHRATION} };
    return this.filterForDeletedShoutOutTypes(organizationID, await this.getAllWithHashKeyAndRangeKeyAndSortDirection(this.api.SurveysByParticipantTypeDate, participantEmail, range, null, filter,  {limit: 100000}));
  }

  async filterForDeletedShoutOutTypes(organizationID: string,surveys: Survey[]): Promise<Survey[]> {
    const filterIDs: string[] = [];
    for (const item of surveys) {
      if (!filterIDs.includes(item.objectID)) {
        const orgShoutOutType = await this.shoutOutService.getMemoizedOrganizationShoutOutType(organizationID, item.objectID);
        if (!orgShoutOutType || orgShoutOutType.shoutOutType.status == ShoutOutTypeStatus.DELETED) {
          filterIDs.push(item.objectID);
        }
      }
    }
    return surveys.filter(s => !filterIDs.includes(s.objectID));
  }

  private async getEventSurveyByEmail(organizationID: string, retrieval: RetrievalType, email: string, surveyResponseFilterDirection: SurveyResponseFilterDirection | SurveyResponseFilterDirection.ne | SurveyResponseFilterDirection.eq | SurveyResponseFilterDirection.le | SurveyResponseFilterDirection.lt | SurveyResponseFilterDirection.ge | SurveyResponseFilterDirection.gt, surveyResponseOption: EventSurveyResponseOptions, oldestSurveyResponseDate: string) {
    let surveyDate = moment().subtract(1, 'year').subtract(1, 'day').toISOString();
    if (oldestSurveyResponseDate) {
      surveyDate = oldestSurveyResponseDate;
    }
    const filter: ModelSurveyFilterInput = {
      surveyDate: {gt: surveyDate }
    }
    const range: ModelSurveyByRespondentTypeDateCompositeKeyConditionInput = { eq: {surveyType: SurveyType.EVENT} };

    let results;
    if (retrieval == RetrievalType.RESPONDENT) {
      results = await this.getAllWithHashKeyAndRangeKeyAndSortDirection<Survey>(this.api.SurveysByRespondentTypeDate, email, range, null, filter);
    } else {
      results = await this.getAllWithHashKeyAndRangeKeyAndSortDirection<Survey>(this.api.SurveysByParticipantTypeDate, email, range, null, filter);
    }

    switch (surveyResponseFilterDirection) {
      case SurveyResponseFilterDirection.eq: {
        results = results.filter(s => s.surveyResponse == surveyResponseOption);
        break;
      }
      case SurveyResponseFilterDirection.ge: {
        results = results.filter(s => s.surveyResponse >= surveyResponseOption);
        break;
      }
      case SurveyResponseFilterDirection.gt: {
        results = results.filter(s => s.surveyResponse > surveyResponseOption);
        break;
      }
      case SurveyResponseFilterDirection.le: {
        results = results.filter(s => s.surveyResponse <= surveyResponseOption);
        break;
      }
      case SurveyResponseFilterDirection.lt: {
        results = results.filter(s => s.surveyResponse < surveyResponseOption);
        break;
      }
      case SurveyResponseFilterDirection.ne: {
        results = results.filter(s => s.surveyResponse != surveyResponseOption);
        break;
      }
    }
    return results;
  }

  private _getSurveys(organizationID: string, filter:ModelSurveyFilterInput, options?: GetAllOptions): Promise<Survey[]> {
    return this.getAllWithHashKeyAndRangeKeyAndSortDirection<Survey>(this.api.SurveysByOrgTypeDate, organizationID, null, null, filter, options);
  }

}

enum RetrievalType {
  RESPONDENT = "respondentEmail",
  PARTICIPANT = "participantEmail"
}
