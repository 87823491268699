import { Injectable } from "@angular/core";
import {
  APIService,
  CreateObjectiveAssignmentInput,
  CreateOrganizationObjectiveInput,
  ModelObjectiveAssignmentFilterInput,
  ModelObjectiveDomainFilterInput,
  ModelOrganizationObjectiveFilterInput,
  UpdateObjectiveAssignmentInput
} from "./API.service";
import { Memoize, MEMOIZE_FN_MAP } from "@inthraction/utils";
import {
  CadenceObjectiveAssignment,
  Objective,
  ObjectiveAssignment,
  ObjectiveDomain,
  ObjectiveFocus,
  ObjectivePriority,
  OrganizationObjective,
  QuantifiableObjectiveAssignment
} from "@inthraction/data-models";
import { BaseService } from "./base.service";
import { ObjectiveTypeCodes, UserDefinedObjectiveDomainTypes } from "@inthraction/codes";
import { AuthService } from "./auth.service";
import * as moment from "moment";

@Injectable({ providedIn: "root" })
export class ObjectiveService extends BaseService {

  constructor(
    protected api: APIService,
    protected authService: AuthService
  ) {
    super(api, authService);
  }

  async getObjectiveAssignment(id: string): Promise<ObjectiveAssignment | QuantifiableObjectiveAssignment | CadenceObjectiveAssignment> {
    return id ? this.api.GetObjectiveAssignment(id) : null;
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getObjectiveAssignmentMemoize(id: string): Promise<ObjectiveAssignment | QuantifiableObjectiveAssignment | CadenceObjectiveAssignment> {
    return this.getObjectiveAssignment(id);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getObjectiveAssignmentByOrganization(organizationID: string): Promise<Array<ObjectiveAssignment | QuantifiableObjectiveAssignment | CadenceObjectiveAssignment>> {
    const filter: ModelObjectiveAssignmentFilterInput = {
      organizationID: {eq: organizationID}
    }
    return this.getAll(this.api.ListObjectiveAssignments, filter, {limit:10000});
  }


  async deleteObjectiveAssignment(objectiveAssignmentID: string) {
    await this.api.UpdateObjectiveAssignment({
      id: objectiveAssignmentID,
      status: "DELETED",
      endDate: moment().format("YYYY-MM-DD")
    });
  }

  async getObjectives(organizationID?: string): Promise<Objective[]> {
    let filter;
    if (organizationID && organizationID.length > 0) {
      filter = { and: [{ organizationID: { eq: organizationID } }, { status: { ne: "DELETED" } }] };
    } else {
      filter = { and: [{ id: { ne: null } }, { organizationID: { attributeExists: false } }, { status: { ne: "DELETED" } }] };
    }
    return this.getAll<Objective>(this.api.ListObjectives, filter);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getObjectivesMemoize(organizationID?: string): Promise<Objective[]> {
    const list = await this.getObjectives(organizationID);
    return list.filter(o => o.domain.key !== UserDefinedObjectiveDomainTypes.CONSULTANT_DEFINED);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getObjectivesForOrganizationByDomainMemoize(organizationID, objectiveDomainKey: string): Promise<Objective[]> {
    const list = await this.getObjectives(organizationID);
    return list.filter(o => o.domain.key === objectiveDomainKey);
  }

  async getActiveObjectiveAssignmentsByEmployee(employeeID: string): Promise<Array<ObjectiveAssignment | CadenceObjectiveAssignment>> {
    const filter: ModelObjectiveAssignmentFilterInput = {
      and: [
        { status: { ne: "DELETED" } },
        { status: { ne: "EXPIRED" } },
        { id: { ne: null } },
        {
          or: [
            { objectiveType: { eq: ObjectiveTypeCodes.DEFINED } },
            { objectiveType: { eq: ObjectiveTypeCodes.DEFINED_MANAGER } },
            { objectiveType: { eq: ObjectiveTypeCodes.DEFINED_SELF } },
            { objectiveType: { eq: null } }
          ]
        }
      ]
    };

    filter.and.push({ employeeId: { eq: employeeID } });

    return this.getAll<ObjectiveAssignment | CadenceObjectiveAssignment>(this.api.ListObjectiveAssignments, filter);
  }

  async getActiveObjectiveAssignments(organizationID?: string): Promise<Array<ObjectiveAssignment | CadenceObjectiveAssignment>> {
    const filter: ModelObjectiveAssignmentFilterInput = {
      and: [
        { status: { ne: "DELETED" } },
        { status: { ne: "EXPIRED" } },
        { id: { ne: null } },
        {
          or: [
            { objectiveType: { eq: ObjectiveTypeCodes.DEFINED } },
            { objectiveType: { eq: ObjectiveTypeCodes.DEFINED_MANAGER } },
            { objectiveType: { eq: ObjectiveTypeCodes.DEFINED_SELF } },
            { objectiveType: { eq: null } }
          ]
        }
      ]
    };

    if (organizationID) {
      filter.and.push({ organizationID: { eq: organizationID } });
    }


    return this.getAll<ObjectiveAssignment | CadenceObjectiveAssignment>(this.api.ListObjectiveAssignments, filter);
  }

  async getActiveObjectives(organizationID?: string): Promise<Set<string>> {
    const filter: ModelObjectiveAssignmentFilterInput = {
      and: [
        { status: { ne: "DELETED" } },
        { status: { ne: "EXPIRED" } },
        { id: { ne: null } },
        {
          or: [
            { objectiveType: { eq: ObjectiveTypeCodes.DEFINED } },
            { objectiveType: { eq: ObjectiveTypeCodes.DEFINED_MANAGER } },
            { objectiveType: { eq: ObjectiveTypeCodes.DEFINED_SELF } },
            { objectiveType: { eq: null } }
          ]
        }
      ]
    };

    if (organizationID) {
      filter.and.push({ organizationID: { eq: organizationID } });
    }

    const objectiveAssignments = await this.getAll<ObjectiveAssignment | CadenceObjectiveAssignment>(this.api.ListObjectiveAssignments, filter);
    return new Set<string>(
      objectiveAssignments
        .map(a => a.orgObjective?.objective?.id)
        .filter((elm, pos, array) => {
          return array.indexOf(elm) == pos;
        })
    );
  }

  async getObjectiveDomains(): Promise<ObjectiveDomain[]> {
    const filter = { id: { ne: null } };
    return this.getAll<ObjectiveDomain>(this.api.ListObjectiveDomains, filter);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getObjectiveDomainsMemoize(): Promise<ObjectiveDomain[]> {
    return this.getObjectiveDomains();
  }

  async getOrganizationObjectivesByOrganizationIDAndObjectiveID(organizationID: string, objectiveID: string, enabled: boolean = true): Promise<OrganizationObjective> {
    let filter: ModelOrganizationObjectiveFilterInput;
    if (enabled) {
      filter = {
        and: [
          { organizationID: { eq: organizationID } },
          { organizationObjectiveObjectiveId: { eq: objectiveID } },
          { enabled: { eq: enabled } },
          { status: { ne: "DELETED" } }
        ]
      };
    } else {
      filter = {
        and: [
          { organizationID: { eq: organizationID } },
          { organizationObjectiveObjectiveId: { eq: objectiveID } },
          { status: { ne: "DELETED" } }
        ]
      };
    }
    const list = await this.getAll<OrganizationObjective>(this.api.ListOrganizationObjectives, filter);
    return (list && list.length) ? list[0] : null;
  }

  async getOrganizationObjectivesByOrganizationID(organizationID: string, enabled: boolean = true): Promise<OrganizationObjective[]> {
    let filter;
    if (enabled) {
      filter = {
        and: [
          { organizationID: { eq: organizationID } },
          { enabled: { eq: enabled } },
          { status: { ne: "DELETED" } }
        ]
      };
    } else {
      filter = { and: [{ organizationID: { eq: organizationID } }, { status: { ne: "DELETED" } }] };
    }
    const list = await this.getAll<OrganizationObjective>(this.api.ListOrganizationObjectives, filter);
    return list.filter(o => o.objective.domain.key !== UserDefinedObjectiveDomainTypes.CONSULTANT_DEFINED);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getOrganizationObjectivesByOrganizationIDMemoize(organizationID: string, enabled: boolean = true): Promise<OrganizationObjective[]> {
    return this.getOrganizationObjectivesByOrganizationID(organizationID, enabled);
  }

  async createNewOrganizationObjective(createOrganizationObjectiveInput: CreateOrganizationObjectiveInput): Promise<OrganizationObjective> {
    this.clearMemoizedOrganizationObjectives(createOrganizationObjectiveInput.organizationID);
    // TODO The primary key for this table should be orgID and objectiveID but until then, check for duplicate before creating.
    const existingOrganizationObjective = await this.getOrganizationObjectivesByOrganizationIDAndObjectiveID(createOrganizationObjectiveInput.organizationID, createOrganizationObjectiveInput.organizationObjectiveObjectiveId, false);
    if (!existingOrganizationObjective) {
      return this.api.CreateOrganizationObjective({
        organizationID: createOrganizationObjectiveInput.organizationID,
        organizationObjectiveObjectiveId: createOrganizationObjectiveInput.organizationObjectiveObjectiveId,
        enabled: createOrganizationObjectiveInput.enabled,
        businessObjective: createOrganizationObjectiveInput.businessObjective,
        status: createOrganizationObjectiveInput.status
      });
    } else {
      console.error("Can't create new Organization Objective, one for this Organization Objective already exits:  NEW / OLD", createOrganizationObjectiveInput, existingOrganizationObjective);
      return existingOrganizationObjective;
    }
  }

  async updateOrganizationObjective(organizationObjective: UpdateOrganizationObjectiveType): Promise<OrganizationObjective> {
    if (organizationObjective.businessObjective && organizationObjective.businessObjective.length <= 0) {
      organizationObjective.businessObjective = null;
    }
    this.clearMemoizedOrganizationObjectives(organizationObjective.organizationID);
    return this.api.UpdateOrganizationObjective(organizationObjective);
  }

  @Memoize()
  async getPrioritiesMemoize(): Promise<ObjectivePriority[]> {
    return this.getAll<ObjectivePriority>(this.api.ListPrioritys, null);
  }

  @Memoize()
  async getFocusMemoize(): Promise<ObjectiveFocus[]> {
    return this.getAll<ObjectiveFocus>(this.api.ListFocuss, null);
  }


  clearObjectiveDomains(): void {
    if(MEMOIZE_FN_MAP.has("getObjectiveDomainsMemoize")) {
      MEMOIZE_FN_MAP.get("getObjectiveDomainsMemoize").clear()
    }
  }
  clearMemoizedObjectives(objectiveID?: string, organizationID?: string): void {
    if (MEMOIZE_FN_MAP.has("getObjectivesMemoize")) {
      MEMOIZE_FN_MAP.get("getObjectivesMemoize").delete(null);
      MEMOIZE_FN_MAP.get("getObjectivesMemoize").delete(organizationID);
    }
    if (MEMOIZE_FN_MAP.has("getObjectivesForOrganizationByDomainMemoize")) {
      MEMOIZE_FN_MAP.get("getObjectivesForOrganizationByDomainMemoize").clear();
    }
  }

  clearMemoizedOrganizationObjectives(organizationID: string): void {
    if (MEMOIZE_FN_MAP.has("getOrganizationObjectivesByOrganizationIDMemoize")) {
      MEMOIZE_FN_MAP.get("getOrganizationObjectivesByOrganizationIDMemoize").delete(organizationID);
      MEMOIZE_FN_MAP.get("getOrganizationObjectivesByOrganizationIDMemoize").delete(organizationID, false);
      MEMOIZE_FN_MAP.get("getOrganizationObjectivesByOrganizationIDMemoize").delete(organizationID, true);
    }
  }

  async updateObjective(objective: Objective): Promise<Objective> {
    this.clearMemoizedObjectives(objective.id, objective.organizationID);
    return this.api.UpdateObjective({
      id: objective.id,
      display: objective.display,
      definition: objective.definition,
      observables: objective.observables,
      hints: objective.hints,
      status: objective.status,
      organizations: objective.organizations ? objective.organizations : []
    });
  }

  async deleteObjective(objectiveID: string, organizationID?: string): Promise<Objective> {
    const objective: Objective = await this.api.GetObjective(objectiveID);
    if (organizationID) {
      this.clearMemoizedOrganizationObjectives(organizationID);
    }
    this.clearMemoizedObjectives(objectiveID, organizationID);
    const orgObjectiveResults = await this.getAll<OrganizationObjective>(this.api.ListOrganizationObjectives, { organizationObjectiveObjectiveId: { eq: objectiveID } });
    for (const organizationObjective of orgObjectiveResults) {
      await this.api.UpdateOrganizationObjective({
        id: organizationObjective.id,
        organizationID: organizationObjective.organizationID,
        organizationObjectiveObjectiveId: organizationObjective.objective.id,
        enabled: organizationObjective.enabled,
        businessObjective: organizationObjective.businessObjective,
        status: "DELETED"
      });
      this.clearMemoizedOrganizationObjectives(organizationObjective.organizationID);
    }
    return this.api.UpdateObjective({
      id: objective.id,
      display: objective.display,
      definition: objective.definition,
      observables: objective.observables,
      hints: objective.hints,
      organizationID: objective.organizationID,
      objectiveDomainId: objective.objectiveDomainId,
      status: "DELETED"
    });
  }

  async createObjective(objective: Objective): Promise<Objective> {
    this.clearMemoizedObjectives();
    return this.api.CreateObjective({
      organizationID: objective.organizationID,
      display: objective.display,
      definition: objective.definition,
      observables: objective.observables,
      objectiveDomainId: objective.objectiveDomainId,
      hints: objective.hints,
      status: objective.status,
      organizations: objective.organizations? objective.organizations : []
    });
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getEmployeeObjectiveAssignmentsMemoize(employeeID: string, includeExpired: boolean = false): Promise<ObjectiveAssignment[]> {
    return this.getEmployeeObjectiveAssignments(employeeID, includeExpired);
  }

  async getEmployeeObjectiveAssignments(employeeID: string, includeExpired: boolean = false): Promise<ObjectiveAssignment[]> {
    const filter: ModelObjectiveAssignmentFilterInput = {
      and: [
        { status: { ne: "DELETED" } },
        { employeeId: { eq: employeeID } }
      ]
    };

    if (!includeExpired) {
      filter.and.push({ status: { ne: "EXPIRED" } });
    }

    return this.getAll<ObjectiveAssignment>(this.api.ListObjectiveAssignments, filter);
  }


  async getEmployeeObjectiveAssignmentsForOrganization(organizationID?: string, includeExpired: boolean = false): Promise<ObjectiveAssignment[]> {
    if (!organizationID) {
      const employee = await this.getCurrentUser();
      organizationID = employee.orgId;
    }

    const filter: ModelObjectiveAssignmentFilterInput = {
      and: [
        { status: { ne: "DELETED" } },
        { organizationID: { eq: organizationID } }
      ]
    };

    if (!includeExpired) {
      filter.and.push({ status: { ne: "EXPIRED" } });
    }

    return this.getAll<ObjectiveAssignment>(this.api.ListObjectiveAssignments, filter);
  }

  async getEmployeeObjectiveAssignmentByID(objectiveAssignmentID: string): Promise<ObjectiveAssignment> {
    return this.api.GetObjectiveAssignment(objectiveAssignmentID);
  }

  clearMemoizedObjectiveAssignments(employeeID: string): void {
    if (MEMOIZE_FN_MAP.has("getEmployeeObjectiveAssignments")) {
      MEMOIZE_FN_MAP.get("getEmployeeObjectiveAssignments").delete(employeeID, true);
      MEMOIZE_FN_MAP.get("getEmployeeObjectiveAssignments").delete(employeeID, false);
    }
  }

  async updateObjectiveAssignment(employeeObjective: UpdateObjectiveAssignmentInput): Promise<ObjectiveAssignment> {
    const assignment = await this.api.UpdateObjectiveAssignment(employeeObjective);
    this.clearMemoizedObjectiveAssignments(assignment.employeeId);
    return assignment;
  }

  async createObjectiveAssignment(employeeObjective: CreateObjectiveAssignmentInput): Promise<ObjectiveAssignment> {
    const assignment = await this.api.CreateObjectiveAssignment(employeeObjective);
    this.clearMemoizedObjectiveAssignments(assignment.employeeId);
    return assignment;
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getObjectiveDomain(id: string): Promise<ObjectiveDomain> {
    return this.api.GetObjectiveDomain(id);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getObjectiveDomainByKey(objectiveDomainKey: string): Promise<ObjectiveDomain> {
    return this._getObjectiveDomainByKey(objectiveDomainKey);
  }


  private async _getObjectiveDomainByKey(objectiveDomainKey: string): Promise<ObjectiveDomain> {
    const filter: ModelObjectiveDomainFilterInput = { key: { eq: objectiveDomainKey } };
    let objectiveDomain: ObjectiveDomain;
    const list = await this.getAll<ObjectiveDomain>(this.api.ListObjectiveDomains, filter);
    if (list && list.length > 0) {
      objectiveDomain = list[0];
    }
    return objectiveDomain;
  }

  async createObjectiveDomain(domain: ObjectiveDomain): Promise<ObjectiveDomain> {
    this.clearObjectiveDomains();
    const _domain = await this._getObjectiveDomainByKey(domain.key);
    if(_domain) {
      console.error("Key already exists, can not create new Objective domain with key: " + domain.key);
    } else {
      return this.api.CreateObjectiveDomain({
        display: domain.display,
        key: domain.key,
        excludeFromTPS: domain.excludeFromTPS,
        sites: domain.sites
      });
    }
    return null;
  }

  async updateObjectiveDomain(domain: ObjectiveDomain) : Promise<ObjectiveDomain> {
    this.clearObjectiveDomains();
    const _domain = await this.getObjectiveDomainByKey(domain.key);
    if (_domain) {
      return this.api.UpdateObjectiveDomain({
        id: _domain.id,
        key: _domain.key,
        display: domain.display,
        excludeFromTPS: domain.excludeFromTPS,
        sites: domain.sites
      });
    } else {
      console.error("No Matching Domain to Update for key: " + domain.key);
      return null;
    }
  }
}

export interface UpdateOrganizationObjectiveType {
  id: string;
  organizationID: string;
  enabled?: boolean | null;
  businessObjective?: string | null;
  organizationObjectiveObjectiveId?: string | null;
}
