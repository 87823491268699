import { Component, OnDestroy, OnInit } from "@angular/core";
import { Employee, EmployeeImpl, Survey } from "@inthraction/data-models";
import { EmployeeService, StandupService, SurveyService } from "@inthraction/services";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { SurveyStatus, SurveyTypeCodes } from "@inthraction/codes";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "inthraction-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.scss"]
})
export class SurveyComponent implements OnInit, OnDestroy {

  static surveyIDParameterName = "survey-id";
  static surveyResponseParameterName = "survey-response";

  employee: Employee;
  survey: Survey;
  surveyResponse: number;
  remainingSurveysCount = 0;

  readonly SurveyTypes = SurveyTypeCodes;

  private subscriptions: Subscription[] = [];
  private completedSurveyIDs: string[] = [];
  private openSurveysMap: Map<string, Survey>;

  constructor(
    protected surveyService: SurveyService,
    private employeeService: EmployeeService,
    private standupService: StandupService,
    private route: ActivatedRoute,
    protected router: Router,
    protected toastr: ToastrService
  ) {
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  async ngOnInit() {

    if (!this.route.routeConfig.path.startsWith("survey")) {
      let path = `/survey/${this.route.snapshot.params["survey-id"]}`;
      if (this.route.snapshot.params["survey-response"]) {
        path = path + `/${this.route.snapshot.params["survey-response"]}`;
      }
      this.router.navigate([path]);
      return;
    }

    this.employee = new EmployeeImpl(await this.employeeService.getCurrentEmployee());

    if (!this.route.snapshot.params || !this.route.snapshot.params["survey-id"]) {
      await this.loadNextSurvey({ completedSurveyIDs: [] });
      return;
    }

    this.subscriptions.push(this.route.paramMap.subscribe(async queryParams => {
      const surveyID = queryParams.get(SurveyComponent.surveyIDParameterName);
      if (!this.survey || this.survey.id !== surveyID) {
        if (this.openSurveysMap && this.openSurveysMap.has(surveyID)) {
          this.survey = this.openSurveysMap.get(surveyID);
        } else {
          const survey = await this.surveyService.getSurveyByID(surveyID);
          if (this.isValidSurvey(survey)) {
            this.survey = survey;
          } else {
            await this.loadNextSurvey({ completedSurveyIDs: [surveyID] });
          }
        }
      }

      let responseValue: number;
      try {
        responseValue = parseInt(queryParams.get(SurveyComponent.surveyResponseParameterName), 10);
      } catch (error) {
        // Ignore parseInt errors
      }
      if (responseValue && !this.surveyResponse || this.surveyResponse !== responseValue) {
        this.surveyResponse = responseValue;
      } else {
        this.surveyResponse = null;
      }

      if (!this.openSurveysMap) {
        this.loadRemainingSurveys(this.employee, this.survey);
      }

    }));
  }

  async loadNextSurvey(event: SurveyCompleteEvent) {
    const currentSurvey = this.survey;
    this.survey = null;
    if (!this.openSurveysMap) {
      await this.loadRemainingSurveys(this.employee, currentSurvey);
    }
    this.completedSurveyIDs.push(...event.completedSurveyIDs);
    if (event?.completedSurveyIDs && event?.completedSurveyIDs?.length) {
      for (const surveyID of event.completedSurveyIDs) {
        this.openSurveysMap.delete(surveyID);
      }
    }
    const nextSurveyID = this.openSurveysMap.keys().next();
    if (nextSurveyID && nextSurveyID.value) {
      const survey = this.openSurveysMap.get(nextSurveyID.value);
      this.remainingSurveysCount = this.openSurveysMap.size;
      this.surveyResponse = null;
      this.survey = survey;
      this.router.navigate([`/survey/${survey.id}`]);
    } else {
      this.router.navigate(["/dashboard"]);
    }
  }

  private async loadRemainingSurveys(employee: Employee, currentSurvey: Survey) {
    const surveyMap: Map<string, Survey> = new Map<string, Survey>();
    if (currentSurvey) {
      surveyMap.set(currentSurvey.id, currentSurvey);
    }
    let openSurveyList: Survey[] = await this.surveyService.getPendingResponseSurveysByRespondentEmail(employee.orgId, employee.email);
    if (openSurveyList && openSurveyList.length > 0) {
      openSurveyList = openSurveyList.sort((a, b) => a.surveyDate.localeCompare(b.surveyDate));
      const matchingSurveys = openSurveyList.filter(s => s.objectID === currentSurvey?.objectID);
      if (matchingSurveys && matchingSurveys.length) {
        for (const survey of matchingSurveys) {
          if (!surveyMap.has(survey.id)) {
            surveyMap.set(survey.id, survey);
          }
        }
      }
      for (const survey of openSurveyList) {
        if (!surveyMap.has(survey.id)) {
          surveyMap.set(survey.id, survey);
        }
      }
      this.remainingSurveysCount = openSurveyList.length;
    }
    if (currentSurvey?.surveyType != SurveyTypeCodes.STANDUP) {
      const standupSurvey = await this.standupService.getPendingStandupSurveyByEmployee(employee.id);
      if (standupSurvey && !surveyMap.has(standupSurvey.id)) {
        surveyMap.set(standupSurvey.id, standupSurvey);
      }
    }
    this.openSurveysMap = surveyMap;
  }

  private isValidSurvey(survey: Survey) {
    let isValid = true;
    if (!survey || survey.status === SurveyStatus.DELETED) {
      // Survey must exist
      this.toastr.error("Requested survey does not exist");
      isValid = false;
    } else if (survey.status === SurveyStatus.MISSED) {
      this.toastr.error("Sorry, it is too late to respond to this survey.");
      isValid = false;
    } else if (survey.respondentEmail !== this.employee.email) {
      // You can't respond to someone else's survey
      this.toastr.error("Requested survey does not exist");
      isValid = false;
    } else if (survey.responseReceived) {
      // You can't respond more than one time
      this.toastr.warning("A response for this survey has already been recorded.");
      isValid = false;
    }
    return isValid;
  }

}

export interface SurveyCompleteEvent {
  completedSurveyIDs: string[];
}
